import Cookies from "js-cookie";

export default defineNuxtRouteMiddleware((to, from, next) => {
  if (!process.server) {
    const isAuthenticated = Cookies.get("token");

    if (!isAuthenticated) {
      return navigateTo("/signin");
    }
  }
});
